import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"

import {
  STORE_DETAILS_API_ENDPOINT,
  STORE_MAX_DISTANCE,
  STORE_MIN_DISTANCE,
} from "@/constants"
import { apim, aemAxiosInstance } from "@/constants/api"
import { locationIpEndPoint, locationZipEndPoint } from "@/constants/index"
import { getConfig } from "@/constants/config"
// import { store } from "@/store"

// Get location using the public ip address of the client
const getLocationUsingIP = async () => {
  try {
    const ip = window?.sessionStorage["true-ip"]
    const ipRegex = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/

    if (ip && ip !== "" && ipRegex.test(ip)) {
      // {"23.100.225.115"}
      const res = await apim.get(`${locationIpEndPoint}${ip}`)
      return res
    } else {
      return {}
    }
  } catch (err) {
    console.error("Failed to get location using IP address", err)
  }
}

// Get location using the zip code
const getLocationUsingZipcode = async zip => {
  try {
    if (zip && zip !== "") {
      const res = await apim.get(`${locationZipEndPoint}${zip}`)
      return res
    } else {
      return {}
    }
  } catch (err) {
    console.error("Failed to get location using zipcode", err)
  }
}

const validateField = async (field, value) => {
  const config = await getConfig()
  const { regexPattern } = config
  const pattern = _get(regexPattern, `map.${field}`, "")
  const regex = new RegExp(pattern)
  const isValid = regex.test(value) ? true : false
  return isValid
}

const validateZip = async zip => {
  const res = await getLocationUsingZipcode(zip)
  if (
    res?.data?.resourceSets &&
    res?.data?.resourceSets[0]?.resources[0]?.address?.postalCode === zip
  )
    return true
  return false
}

const getShortenedUrl = async uUrl => {
  const config = await getConfig()
  const { general } = config
  const shortUrl = general?.shortendPagePath ?? ""
  const htmlRegExp = new RegExp(".html", "g")
  const htmlRemovedUrl = `${uUrl?.replace(htmlRegExp, "")}`
  const shortUrlRegEx = new RegExp(shortUrl, "g")
  return `${htmlRemovedUrl?.replace(shortUrlRegEx, "")}`
}

const getStoreDataById = ({ myStoreId, findStores, siteName }) => {
  return new Promise((resolve, reject) => {
    const selectQuery =
      "EntityID,BPnumber,LocationName,AddressLine,AddressLine2,StateLong,AdminDistrict,PostalCode,Latitude,__Distance,Longitude,Phone,OpenUntil,Locality"
    if (!_isEmpty(findStores)) {
      apim
        .get(findStores, {
          params: {
            $format: "json",
            brand: siteName.toLowerCase(),
            $select: selectQuery,
            $filter: `EntityID Eq ('${myStoreId}')`,
          },
        })
        .then(response => {
          if (response && response.data) {
            resolve(response.data.d.results[0] ?? {})
          } else {
            reject(
              new Error(
                "Find a nearest store: Error occured while fetching data from Get URL Property"
              )
            )
            // log.error(
            //   "Find a nearest store: Error occured while fetching data from Get URL Property"
            // )
          }
        })
        .catch(err => {
          console.log({ err })
          reject(
            new Error(
              "Find a nearest store: Error occured while fetching data from Get URL Property"
            )
          )
        })
      // .catch(err => log.error(err))
    } else {
      // log.error("Find a nearest store: Get API config not found")
    }
  })
}

const getStoreDataByDistance = ({
  utilityDistance = STORE_MIN_DISTANCE,
  findStores,
  userCity,
  siteName,
}) => {
  return new Promise((resolve, reject) => {
    // const locationStore = store.getState()?.location || ""
    // console.log({ locationStore })
    const selectQuery =
      "EntityID,BPnumber,LocationName,AddressLine,AddressLine2,StateLong,AdminDistrict,PostalCode,Latitude,__Distance,Longitude,Phone,OpenUntil,Locality"
    if (!_isEmpty(findStores)) {
      apim
        .get(findStores, {
          params: {
            $format: "json",
            brand: siteName.toLowerCase(),
            spatialFilter: `nearby('${userCity}',${utilityDistance})`,
            $select: selectQuery,
            $filter: "LocationType Eq ('KOHLER Store')",
          },
        })
        .then(response => {
          if (response && response.data) {
            if (!_isEmpty(response.data.d.results[0])) {
              const utilityData = response.data.d.results[0]
              resolve(utilityData)
            } else if (utilityDistance === 64) {
              getStoreDataByDistance({
                utilityDistance: STORE_MAX_DISTANCE,
                findStores,
                userCity,
              })
            }
          } else {
            reject(
              new Error(
                "Find a nearest store: Error occured while fetching data from Get URL Property"
              )
            )
            // log.error(
            //   "Find a nearest store: Error occured while fetching data from Get URL Property"
            // )
          }
        })
        .catch(err => {
          console.log({ err })
          // log.error(err)
        })
    } else {
      // log.error("Find a nearest store: Get API config not found")
    }
  })
}

const getUtilityStoreDetails = async ({ utilityStoreDetailsUrl }) => {
  try {
    const response = await aemAxiosInstance({
      url: STORE_DETAILS_API_ENDPOINT,
      params: {
        path: utilityStoreDetailsUrl,
      },
    })
    if (response && response.data) {
      return response.data
      // this.setState({
      //   utilityStoreDetails: response.data ?? {},
      // })
    } else {
      // log.error(
      //   "Store Details: Error occured while fetching data from Get URL Property"
      // )
    }
  } catch (error) {
    // log.error(error)
  }
}

export {
  getLocationUsingIP,
  getLocationUsingZipcode,
  validateField,
  validateZip,
  getShortenedUrl,
  getStoreDataById,
  getStoreDataByDistance,
  getUtilityStoreDetails,
}
