export const convertToPhoneNumber = text => {
  try {
    if (text) {
      const value = text
      const x = value.replace(/\D/g, "")
      return x
    } else {
      return
    }
  } catch (error) {
    console.error(error)
  }
}
export const getStateName = (stateVal, countryVal, data) => {
  let country = {}
  if (data.find(obj => obj.name === countryVal)) {
    country = data.find(obj => obj.name === countryVal)
  } else {
    country = data.find(obj => obj.code === countryVal) ?? {}
  }
  if (
    country.states?.find(
      obj => obj.name.toLowerCase() === stateVal.toLowerCase()
    )
  )
    return stateVal
  const state = country.states?.find(obj => obj.code === stateVal) ?? {}
  return state.name ?? ""
}

export * from "../src/js/helperV2"
