const findAStoreUrl = "/maps/stores"
const pinCodeUrl = "/maps/location/zipcode"
const customerSupportUrl = "/support/contact-us"
const getDetailsServer = "/common/get-city-state-with-pincode"
const getDetailsClient = "/maps/get-city-state-with-pincode"
const getStoresServer = "/common/stores"
const getStateWithCitiesServer = "/common/stores/states-with-cities"
const submitFeedbackUrl = "/support/feedback/submit"
const createAccountUrl = "/customer/signup"
const getLocationFromIPURL = "/maps/location/ip"
const submitDealershipUrl = "/support/lead/create?type=enquiry"
const submitLeadGenerationUrl = "/support/lead/create"
const sendOTPUrl = "/customer/signup"
const activateUserFactorUrl = "/customer/factor/activate"
export const INSTALL_SERVICES = "Install Services"
export const FIND_BY_PINCODE = "/maps/get-city-state-with-pincode?pincode="
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

export const inventoryStatus = {
  IN_STOCK: "inStock",
  NON_DELIVERABLE: "nonDeliverable",
  OUT_OF_STOCK: "outOfStock",
  PARTIAL_NON_DELIVERABLE: "partialNonDeliverable",
  PARTIAL_OUT_OF_STOCK: "partialOutOfStock",
  PARTIAL_NON_DELIVERABLE_AND_OUT_OF_STOCK:
    "partialNonDeliverableAndOutOfStock",
  INSTALLATION_NOT_AVAILABLE: "installationNotAvailable",
}

export {
  findAStoreUrl,
  pinCodeUrl,
  getDetailsServer,
  getStoresServer,
  getStateWithCitiesServer,
  getDetailsClient,
  customerSupportUrl,
  submitFeedbackUrl,
  createAccountUrl,
  getLocationFromIPURL,
  submitDealershipUrl,
  sendOTPUrl,
  activateUserFactorUrl,
  submitLeadGenerationUrl,
}
