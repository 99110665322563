const setPrerender = (rules, id) => {
  const scriptEl = document.createElement("script")
  scriptEl.type = "speculationrules"

  if(id) {
    scriptEl.setAttribute('data-id', id)
  }

  scriptEl.textContent = JSON.stringify(rules)
  document.body.append(scriptEl)
}

const removePrerender = (id) => {
  document.querySelectorAll(`script[data-id="${id}"]`).forEach(el => el.remove())
}

export const cartPrerender = () => {
  const urls = []
  const tagId = 'cart'

  if(!(window.location.pathname === '/cart' || window.location.pathname === '/checkout')) {
    urls.push('/cart')
  }

  const cartRule = {
    prerender: [
      {
        urls
      },
    ],
  }

  removePrerender(tagId)
  setTimeout(() => setPrerender(cartRule, tagId), 500)
}


export const initPrerender = () => {

  const commonRules = {
    prerender: [
      {
        where: {
          href_matches: "/p/*/*",
        },
        eagerness: "moderate",
      }
    ],
  }

  setPrerender(commonRules, 'common')
  cartPrerender()

}
